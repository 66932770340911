export declare module PricingList {
  type ServiceTypes = 'PRESS' | 'CLEAN_PRESS' | 'WASH_FOLD' | 'HOME_CARE' | 'SHOE_CLEANING' | 'THE_FINERY';
  type PricingTypes = 'PER_BAG' | 'PER_ITEM';
  type ServiceName = 'Clean & Press' | 'Homecare' | 'Press Only' | 'Wash & Fold' | 'ShoeCare' | 'The Finery';

  interface ItemType {
    key: string;
    name: string;
    price: number;
    discountedPrice?: number;
  }

  interface HintItem {
    title?: string;
    description?: string;
    badgeText?: string;
    showBagIcon?: boolean;
    showRedirectIcon?: boolean;
    bagType: ServiceTypes;
  }

  interface ItemCategory {
    key: string;
    name: string;
    itemTypes: ItemType[];
    hintItem?: HintItem;
  }

  interface BaseService {
    name: ServiceName;
    pricingType: PricingTypes;
    price?: number;
    discountedPrice?: number;
  }

  interface PerBagService extends BaseService {
    pricingType: 'PER_BAG';
    maxItemsPerBag: number;
    flatRatePricePerExtraItem: number;
    price: number;
    discountedPrice?: number;
    discountPercentage?: number;
  }

  interface PerItemService extends BaseService {
    pricingType: 'PER_ITEM';
    itemCategories: ItemCategory[];
    discountedItems?: DiscountedItem[];
  }

  type CombinedService = PerBagService | PerItemService;

  type Services = {
    [key in ServiceTypes]: CombinedService;
  };

  export interface Charges {
    delivery: number;
    discountedDelivery?: number;
    minimumAfterVat: number;
    discountedMinimumAfterVat?: number;
    minimumBeforeVat: number;
    discountedMinimumBeforeVat?: number;
    vatPercentage: number;
    service: {
      flex: number;
      priority: number;
      discountedPriority?: number;
    };
  }

  type BillingConstants = {
    charges: Charges;
  };

  interface Pricing {
    currency: string;
    services: Services;
    billingConstants: BillingConstants;
  }

  export interface RootObject {
    pricing: Pricing;
  }
}

export function isPerBagService(service: PricingList.PerBagService | PricingList.PerItemService): service is PricingList.PerBagService {
  if ((service as PricingList.PerBagService).pricingType === 'PER_BAG') {
    return true;
  }
  return false;
}

export function isPerItemService(service: PricingList.PerBagService | PricingList.PerItemService): service is PricingList.PerItemService {
  if ((service as PricingList.PerItemService).pricingType === 'PER_ITEM') {
    return true;
  }
  return false;
}

interface DiscountedItem {
  key: string;
  category: string;
  discountPercentage: number;
}
